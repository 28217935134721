<template>
  <div class="image-block">
    <div class="wrapper">
      <h2 v-if="title" class="text-subheader">{{ title }}</h2>
      <UIPictureTag
        class="image-block__image"
        :image-seo="imageSeo"
        :image-webp="`${url}${image.webp}`"
        :image-original="`${url}${image.source}`"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ImageItem, ImageSEO } from '@/types/pages';
import { useRuntimeConfig } from '#imports';
import UIPictureTag from '~/components/UI/picture/UIPictureTag.vue';
import './styles.scss';

const url = useRuntimeConfig().public.siteUrl;

interface Props {
  title: string;
  imageSeo: ImageSEO;
  image: ImageItem;
}

defineProps<Props>();
</script>

<style scoped></style>
